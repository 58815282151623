import React from "react"

import Layout from "../components/layout"

const ResumePage = () => {
  return null

  return (
    <Layout title="Resume">
      <p className="text-3xl font-semibold">Resume</p>
      <div className="mt-10">
        <div>
          <a
            href={"/resume.pdf"}
            target="_blank"
            rel="noreferrer"
            className="text-blue-300 font-medium"
          >
            View in PDF
          </a>
        </div>
      </div>
    </Layout>
  )
}

export default ResumePage
